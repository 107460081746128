import * as React from 'react';
import '../Styles/DashboardPanel.css';
import { Link } from 'react-router-dom';
import { IAuthenticatedAppData } from '../../../AuthenticatedApp/AuthenticatedApp';
import { OfflineTemplatePanel, ConnectionStatePanel} from '../Components';

interface IDashboardPanelProps {
    applicationData: IAuthenticatedAppData;
    actions: any;
    placeholder: any;
}

interface IDashboardPanelState {
    
}

export class DashboardPanel extends React.Component<IDashboardPanelProps, IDashboardPanelState> {
    constructor(props: IDashboardPanelProps) {
        super(props);
    }

    render() {
        return (
            <div className="dashboard-panel">
                <div className="dashboard-panel-item actions-panel">
                    <h5 className="text-primary text-uppercase">{this.props.applicationData.resources.Action}</h5>
                    {this.props.actions || <p className="text-muted font-italic">{this.props.placeholder}</p>}
                </div>
                <div className="dashboard-panel-item status-panel">
                    <div className="row">
                        <div className="col">
                            <Link to="/Dashboard/Multiple/Queue">{this.props.applicationData.resources.MultipleRecordsQueued}</Link>
                        </div>
                        <div className="col-md-auto">
                            {this.props.applicationData.multipleQueueCount}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <Link to="/Dashboard/DeferredQueue">{this.props.applicationData.resources.RecordsQueued}</Link>
                        </div>
                        <div className="col-md-auto">
                            {this.props.applicationData.deferredQueueCount}
                        </div>
                    </div>
                </div>
                {!this.props.applicationData.userInfo.UserRole.match(/SuperUser/g) &&
                    <div className="dashboard-panel-item status-panel">
                        <OfflineTemplatePanel applicationData={this.props.applicationData} />
                    </div>
                }
                <div className="dashboard-panel-item status-panel">
                    <ConnectionStatePanel applicationData={this.props.applicationData} />
                </div>
            </div>
        );
    }
}