import * as React from 'react';
import { IRequestLookupFilters } from '../Types';
import { Row, Col, InputGroup, InputGroupAddon, InputGroupText, Input, Button, FormFeedback } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { isNullOrWhiteSpace } from '../../../../../../../Services';
import { IResources } from '../../../../../../../Types';

interface IUprcFilterProps {
    resources: IResources;
    fetchResults: (filters: IRequestLookupFilters) => void;
}

interface IUprcFilterState {
    uprcIsInvalid: boolean;
}

export class UprcFilter extends React.Component<IUprcFilterProps, IUprcFilterState>{
    uprcInput: React.RefObject<HTMLInputElement>;

    constructor(props: IUprcFilterProps) {
        super(props);

        this.uprcInput = React.createRef<HTMLInputElement>(); 

        this.state = {
            uprcIsInvalid: false
        }
    }

    search = () => {
        var uprc = this.uprcInput.current?.value;

        if (isNullOrWhiteSpace(uprc)) {
            this.setState({ uprcIsInvalid: true });
        } else {
            this.props.fetchResults({
                uprc: uprc
            } as IRequestLookupFilters);
        }
    }
    
    render() {
        return (
            <Row style={{ alignItems: "center" }}>
                <Col>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>{this.props.resources.UPRC}</InputGroupText>
                        </InputGroupAddon>
                        <Input innerRef={this.uprcInput} invalid={this.state.uprcIsInvalid} onChange={() => { if (this.state.uprcIsInvalid) { this.setState({ ... this.state, uprcIsInvalid: false }); } }} />
                        {this.state.uprcIsInvalid && <FormFeedback>{this.props.resources.Registration_Validation_RequiredField}</FormFeedback>}
                    </InputGroup>
                </Col>
                <Col xs="auto" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Button size="lg" outline color="primary" onClick={this.search}><FontAwesomeIcon icon={faSearch} /></Button>
                </Col>
            </Row>
        );
    }
}