const Endpoints = {
    GetResources: "/localization/GetResources",
    GetSupportedLanguages: "/localization/GetSupportedLanguages",
    GetSelectedLanguage: "/localization/GetCurrentCulture",
    GetUserInfo: "/users/GetUserData",
    GetAvailableActions: "/api/Pack/GetAvailableActions",
    GetCalibrationData: "/ScannerCalibration/GetCalibrationData",
    GetLocations: "/api/Location/Get",
    GetApiProvider: "/AppSettings/GetApiProvider",
    GetReports: "/reports/GetReports",
    GetMultipleQueue: "/reports/GetMultipleQueue",
    GetMultipleQueueCount: "/api/Pack/GetQueuedItemsCount",
    GetDeferredQueueCount: "/api/DeferredRequest/GetQueuedItemsCount",
    GetDeferredQueue: "/reports/GetDeferredQueue",
    GetRequestLookup: "/reports/GetRequest",
    GetReportsExport: "/reports/GetReportsExport",
    GetDocument: "/DocumentDownload/GetDocument",
    SendTwoFactorCode: "/api/TwoFactorAuthentication/SendTwoFactorCode",
    ValidateTwoFactorCode: "/api/TwoFactorAuthentication/ValidateTwoFactorCode",
    RegisterOrganisation: "/api/Organisation/RegisterOrganisation",
    DownloadOfflineTemplate: "/api/OfflineTemplate/GetTemplate",
    UploadOfflineTemplate: "/api/OfflineTemplate/UploadTemplate",
    CheckForTemplate: "/api/OfflineTemplate/CheckForTemplate",
    GetVersion: "/AppSettings/GetVersion",
    SetUserPasswordResetFalse: "/users/ResetComplete",
    GetIdentifier: "/Identifier/Get",
    PersistScanLogs: "/Log/ScanLog",
    GetMvpLink: "/AppSettings/GetMvpLink",
    GetShowHelpLink: "/AppSettings/GetShowHelpLink",
    GetLocationExpiry: "/api/Location/GetExpiry",
    RenewCert: "api/Location/RenewCert",
    ArvatoChangePassword: "api/Location/ArvatoChangePassword",
    ClearDeferredQueue: "/api/DeferredRequest/ClearQueue"
};

export default Endpoints;