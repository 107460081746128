
import './Styles/custom.scss';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Router, Switch, Route } from 'react-router-dom';
//import App from './App';
//import { EventHandlerProvider } from './Services/EventHandlerService';
import registerServiceWorker from './registerServiceWorker';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
//import { Auth } from './Services/AuthenticationService';
//import { SignalRConnection } from './Services/ConnectionService';
//import { VeriLiteErrorEvent, IVeriLiteEventAction, IVeriLiteEvent } from './Types';
//import { EventModal } from './Components/EventModal';
//import AppLoader, { IAppLoaderState } from './AppLoader'; 
import { AuthenticatedApp } from './AuthenticatedApp';
import { AnonymousApp } from './AnonymousApp';
import NotFound from './NotFound';
import History from './Utils/History';
import { resolve } from "path";

import { config } from "dotenv";
import { getJson, Auth } from './Services';
import Endpoints from './Utils/Endpoints';
import { IResources } from './Types';

config();



var reCaptchaEndpoint = `https://www.google.com/recaptcha/api.js?hl=${ document.cookie.split('; .AspNetCore.Culture=').pop()?.split(';').shift()?.split('uic%3D').pop() || "en"}`;
var scriptTag = document.createElement("script");
scriptTag.src = reCaptchaEndpoint;
document.head.appendChild(scriptTag);


declare global {
    interface Window {
        grecaptcha: any;
        verilite_registration_captcha_callback: any;
        verilite_registration_captcha_expired_callback: any;
        resources: IResources;
    }
}


getJson(Endpoints.GetResources, undefined, false).then(json => {
    window.resources = JSON.parse(json);
})

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') || undefined;
const rootElement = document.getElementById('root');
//let widthCondition = window.innerWidth > 768;
ReactDOM.render(
    <Router history={History}>
        <Switch>
            <Route exact path={['/', '/Dashboard/:path?/:path2?', '/CalibrateScanner', '/Settings/:path?', '/EchoPage', '/Downloads']} render={(props) => <AuthenticatedApp {...props} />} />

            <Route exact path={['/Registration/:path?']} render={(props) => <AnonymousApp {...props} />} />

            <Route render={() => <NotFound />} />
        </Switch>
    </Router>,
    rootElement);

registerServiceWorker();

//{
//    shouldRenderApp() ?
//    widthCondition ?
//        <App />
//        :
//        <div>Mobile support comming soon!</div>
//    :

//    Auth.isCallback ? "" :
//        Auth.hasError ? renderAuthError() :
//            <div>Please wait while you are redirected to the login page</div>;
//}