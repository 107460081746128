import * as React from 'react';


import {
    Button,
    Collapse,
    Container,
    Nav,
    Navbar,
    NavbarBrand,
    NavbarToggler,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { AuthConsumer, GlobalConsumer } from '../Context';
import '../Styles/Header.css'

export class Header extends React.Component<any, any> {

    constructor(props: any) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        return (
            <GlobalConsumer>
                {context => context && (
                    <AuthConsumer>
                        {auth => auth && (
                            <Navbar className="border-bottom mb-3" color="primary" expand="sm" light>
                                <Container>
                                    <NavbarToggler onClick={this.toggleNavbar} className="mr-2 navbar-dark" />
                                    <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                                        <Nav navbar>
                                            <NavItem className="text-light my-2 ">
                                                {this.props.resources.Welcome}
                                            </NavItem>

                                            <UncontrolledDropdown nav inNavbar>
                                                <DropdownToggle className="text-light" nav>
                                                    <span className="mr-1">{auth.getUser().name}</span>
                                                    <FontAwesomeIcon className="nav-item-user-icon" icon={faUserCircle} />
                                                </DropdownToggle>
                                                <DropdownMenu container="body">
                                                    <DropdownItem tag={Link} to="/" onClick={auth.editProfile}>{this.props.resources.EditProfile}</DropdownItem>
                                                    <DropdownItem tag={Link} to="/CalibrateScanner">{this.props.resources.CalibrateScanner}</DropdownItem>
                                                    <DropdownItem tag={Link} to="/Downloads">Downloads</DropdownItem>
                                                    {context.userInfo.UserRole.match(/Administrator/g) &&
                                                        < DropdownItem tag={Link} to="/Settings">{this.props.resources.AdminSettings}</DropdownItem>
                                                    }
                                                    <DropdownItem tag={Link} to="/" onClick={auth.passwordReset}>{this.props.resources.ResetPassword}</DropdownItem>
                                                        <DropdownItem divider />
                                                    <DropdownItem>
                                                        <div onClick={() => auth.logout()}>{this.props.resources.LogOut}</div>
                                                    </DropdownItem>
                                        </DropdownMenu>
                                            </UncontrolledDropdown>

                                            <NavItem className="text-light my-2">
                                                <small>{this.props.resources.Not} {auth.getUser().name}? {this.props.resources.Please}</small>
                                            </NavItem>
                                            <NavLink className="text-light pl-1 pointer" onClick={() => auth.logout()}>
                                                <small>{this.props.resources.LogOut}</small>
                                            </NavLink>
                                        </Nav>
                                    </Collapse>
                                </Container>
                            </Navbar>
                        )}
                    </AuthConsumer>
                )}
            </GlobalConsumer>
        );
    }
}