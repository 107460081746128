import * as React from 'react';
import { HistoryFilters} from './Components';
import { postJson, UserRequestHeaders, convertExpiryDate } from '../../../../../../Services/Util';
import Endpoints from '../../../../../../Utils/Endpoints';
import { Auth } from '../../../../../../Services/AuthenticationService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import "./Styles/VeriLiteHistory.css";
import { ReportsRequestService, EventHandlerProvider } from '../../../../../../Services';
import { IReportsRequestData, ReportGenerator } from '../../Types';
import { ReportsTable, ExportHandler } from '../../Components';
import { IReportsRequest } from './Types';
import { Button } from 'reactstrap';
import { VeriLiteInformationEvent, IResources } from '../../../../../../Types';
import { IAuthenticatedAppData } from '../../../../../../AuthenticatedApp/AuthenticatedApp';

interface IVeriLiteHistoryProps {
    applicationData: IAuthenticatedAppData;
}

interface IVeriLiteHistoryState {
    request: IReportsRequest;
    results: IReportsRequestData[];
    pending: boolean;
    tokens: any[];
    nonExpandable: any[];
    expanded: any[];
}

export class VeriLiteHistory extends React.Component<IVeriLiteHistoryProps, IVeriLiteHistoryState>{
    requestTimer: number;
    ExportHandlerRef: React.RefObject<ExportHandler>;
    constructor(props: IVeriLiteHistoryProps) {
        super(props);
        this.state = {
            results: [],
            request: {
                token: null,
                page: 1,
                itemsCount: 10
            } as IReportsRequest,
            pending: true,
            tokens: [],
            nonExpandable: [],
            expanded: []
        };
        this.requestTimer = 0;
        this.ExportHandlerRef = React.createRef<ExportHandler>();
    }

    handledExpand = (id: any) => {
        var results = this.state.results.map((result) => {
            if (result.id == id) {
                result.serialNumber = <span style={{ cursor: "pointer" }} onClick={() => { this.handledExpand(id); }}><FontAwesomeIcon className="mr-2" icon={this.state.expanded.includes(id) ? faPlusCircle : faMinusCircle} />{result.action} ({result.packs?.length})</span>;
            }
            return result;
        });

        this.setState({
            expanded: this.state.expanded.includes(id) ? this.state.expanded.filter(x => x !== id) : [...this.state.expanded, id],
            results: results
        });
    };

    onExpand = (row: any, isExpand: any, rowIndex: any, e: any) => {

    };

    fetchResults = () => {
        var body: string = JSON.stringify(this.state.request);
        postJson(Endpoints.GetReports, body, undefined, true,  new UserRequestHeaders({ ...Auth.getUser(), Location: this.props.applicationData.selectedLocation })).then((results) => {
            var j = JSON.parse(results);
            var reportRows = ReportsRequestService.parseServerData(j.records);
            var data: IReportsRequestData[] = [];
            var nonExpandable: any[] = [];
            reportRows.map((row) => {
                nonExpandable.push(row.id);
                data.push(ReportGenerator.getReport(row, row.id, this.props.applicationData.resources, () => { this.handledExpand(row.id); }));
            });
            var tokens = this.state.tokens;
            if (j.token) {
                tokens.push(j.token);
            }
            this.setState((prevState) => ({
                results: data,
                pending: false,
                tokens: tokens,
                nonExpandable: nonExpandable
            }));
        })
            .catch((err) => console.error(err));
    };

    filterUpdate = (startDate: string, endDate: string, action: string, hasAlerts: boolean) => {
        this.requestTimer = Date.now();

        this.setState((prevState) => ({
            results: [],
            tokens: [],
            request: { ...prevState.request, startDate: startDate, endDate: endDate, action: action, hasAlerts: hasAlerts, token: null, page: 1 },
            pending: true
        }),
            this.fetchResults);
    };

    tableUpdate = (page: number, itemCount: number) => {
        this.setState({ pending: true, results: [] });
        var tokens = this.state.tokens;
        if (this.state.request.page > page) {
            tokens.pop();
            if (tokens.length == page) {
                tokens.pop();
            }
        }

        var token = tokens.length > 0 ? tokens[tokens.length - 1] : null;

        this.setState((prevState) => ({
            request: { ...prevState.request, page: page, itemCount: itemCount, token: token },
            pending: true,
            tokens: tokens
        }),
            this.fetchResults);
    };

    getTablePage = () => {
        var totalSize = (this.state.request.page * 10) + (this.state.request.page <= this.state.tokens.length ? 1 : 0);
        return totalSize;
    };

    getExpandedRow = (): any => {
        return {
            renderer: (row: IReportsRequestData) => {
                var packs = row.packs?.map((pack) => {
                    return (
                        <tr key={pack.serialNumber}>
                            <td style={{ width: "30%" }}>
                                {pack.serialNumber}
                            </td>
                            <td style={{ width: "30%" }}>
                                {pack.submissionTime}
                            </td>
                            <td style={{ width: "15%" }}>
                                {pack.action}
                            </td>
                            <td style={{ width: "15%" }}>
                                {pack.RAG}
                            </td>
                            <td style={{ width: "10%" }}>
                                {pack.UPRC}
                            </td>
                        </tr >
                    );
                });

                return (
                    <table style={{ width: "100%" }}>
                        <tbody>
                            {packs}
                        </tbody>
                    </table >
                );
            },
            expanded: this.state.expanded,
            nonExpandable: this.state.nonExpandable,
            onExpand: this.onExpand
        };
    };

    sizePerPageOptionRenderer = ({
        text,
        page,
        onSizePerPageChange
    }: any) => (
            <Button disabled={(this.state?.results == null || this.state?.results == undefined) || this.state.results.length == 0} onClick={() => {
                try {
                    var body: string = JSON.stringify(this.state.request);
                    this.ExportHandlerRef.current?.handleExport(postJson(Endpoints.GetReportsExport, body, undefined, true,  new UserRequestHeaders({ ...Auth.getUser(), Location: this.props.applicationData.selectedLocation })));                    
                } catch (e) {
                    console.error(e);
                }

            }}>{this.props.applicationData.resources.Export}</Button>
        );

    render() {
        return (
            <div>
                <HistoryFilters applicationData={this.props.applicationData} fetchResults={this.filterUpdate} />
                <ReportsTable tableChangeHandler={this.tableUpdate} page={this.state.request.page} totalSize={this.getTablePage()} data={this.state.results} pending={this.state.pending} expandRow={this.getExpandedRow()} usePagination={true} exportRenderer={this.sizePerPageOptionRenderer} resources={this.props.applicationData.resources} />
                <ExportHandler ref={this.ExportHandlerRef} resources={this.props.applicationData.resources}/>
            </div>
        );
    }
}