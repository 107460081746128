import * as React from 'react';
import { InputGroup, InputGroupAddon, InputGroupText, Input, Button, Row, Col, FormFeedback } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { IRequestLookupFilters } from '../Types';
import { isNullOrWhiteSpace } from '../../../../../../../Services/Util';
import { IResources } from '../../../../../../../Types';

interface IPackIdentifierFilterProps {
    resources: IResources;
    fetchResults: (filters: IRequestLookupFilters) => void;
}

interface IPackIdentifierFilterState {
    productCodeIsInvalid: boolean;
    serialNumberIsInvalid: boolean;
}

export class PackIdentifierFilter extends React.Component<IPackIdentifierFilterProps, IPackIdentifierFilterState>{
    productCodeInput: React.RefObject<HTMLInputElement>;
    serialNumberInput: React.RefObject<HTMLInputElement>;

    constructor(props: IPackIdentifierFilterProps) {
        super(props);
        this.productCodeInput = React.createRef<HTMLInputElement>();
        this.serialNumberInput = React.createRef<HTMLInputElement>();

        this.state = {
            productCodeIsInvalid: false,
            serialNumberIsInvalid: false
        };
    }

    search = () => {
        var productCode = this.productCodeInput.current?.value;
        var serialNumber = this.serialNumberInput.current?.value;
        var state = {...this.state };
        if (isNullOrWhiteSpace(productCode)) {
            state.productCodeIsInvalid = true;
        }
        if (isNullOrWhiteSpace(serialNumber)) {
            state.serialNumberIsInvalid = true;
        }

        if (state.productCodeIsInvalid || state.serialNumberIsInvalid) {
            this.setState(state);
        } else {
            this.props.fetchResults({
                productCode: productCode,
                serialNumber: serialNumber
            } as IRequestLookupFilters)
        }        
    }

    render() {
        return (
            <Row style={{ alignItems: "center"}}>
                <Col>
                <InputGroup className="mb-2">
                    <InputGroupAddon addonType="prepend">
                            <InputGroupText>{this.props.resources.ProductCode}</InputGroupText>
                    </InputGroupAddon>
                        <Input invalid={this.state.productCodeIsInvalid} innerRef={this.productCodeInput} onChange={() => { if (this.state.productCodeIsInvalid) { this.setState({ ... this.state, productCodeIsInvalid: false }); } }} />
                        {this.state.productCodeIsInvalid && <FormFeedback>{this.props.resources.Registration_Validation_RequiredField}</FormFeedback>}
                </InputGroup>

                <InputGroup>
                    <InputGroupAddon addonType="prepend">
                            <InputGroupText>{this.props.resources.SerialNumber}</InputGroupText>
                    </InputGroupAddon>
                        <Input invalid={this.state.serialNumberIsInvalid} innerRef={this.serialNumberInput} onChange={() => { if (this.state.serialNumberIsInvalid) { this.setState({ ... this.state, serialNumberIsInvalid: false }); } }}/>
                        {this.state.serialNumberIsInvalid && <FormFeedback>{this.props.resources.Registration_Validation_RequiredField}</FormFeedback>}
                    </InputGroup>
                </Col>
                <Col xs="auto" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>            
                    <Button size="lg" outline color="primary" onClick={this.search}><FontAwesomeIcon icon={faSearch} /></Button>
                </Col>
            </Row>
        );
    }
}