import * as React from 'react';
import { Row, Col, InputGroup, InputGroupAddon, InputGroupText, Input, Button, FormFeedback } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { IRequestLookupFilters } from '../Types';
import { isNullOrWhiteSpace } from '../../../../../../../Services/Util';
import { IResources } from '../../../../../../../Types';

interface ITransactionIdFilterProps {
    resources: IResources;
    fetchResults: (filters: IRequestLookupFilters) => void;
}

interface ITransactionIdFilterState {
    transactionIdIsInvalid: boolean;
}

export class TransactionIdFilter extends React.Component<ITransactionIdFilterProps, ITransactionIdFilterState>{
    transactionIdInput: React.RefObject<HTMLInputElement>;

    constructor(props: ITransactionIdFilterProps) {
        super(props);

        this.transactionIdInput = React.createRef<HTMLInputElement>();

        this.state = {
            transactionIdIsInvalid: false
        }
    }

    search = () => {
        var transactionId = this.transactionIdInput.current?.value;

        if (isNullOrWhiteSpace(transactionId)) {
            this.setState({ transactionIdIsInvalid: true})
        } else {
            this.props.fetchResults({
                transactionId: transactionId
            } as IRequestLookupFilters);
        }
    }

    render() {
        return (
            <Row style={{ alignItems: "center" }}>
                <Col>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>{this.props.resources.TransactionId}</InputGroupText>
                        </InputGroupAddon>
                        <Input innerRef={this.transactionIdInput} invalid={this.state.transactionIdIsInvalid} onChange={() => { if (this.state.transactionIdIsInvalid) { this.setState({ ... this.state, transactionIdIsInvalid: false }); } }} />
                        {this.state.transactionIdIsInvalid && <FormFeedback>{this.props.resources.Registration_Validation_RequiredField}</FormFeedback>}
                    </InputGroup>
                </Col>
                <Col xs="auto" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Button size="lg" outline color="primary" onClick={this.search}><FontAwesomeIcon icon={faSearch} /></Button>
                </Col>
            </Row>
        );
    }
}