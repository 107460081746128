import * as React from 'react';
import { Row, Button, Col,Table } from 'reactstrap';
import { postJson, UserRequestHeaders } from '../../Services/Util';
import Endpoints from '../../Utils/Endpoints';
import { Auth } from '../../Services/AuthenticationService';
import { IAuthenticatedAppData } from '../../AuthenticatedApp/AuthenticatedApp';
import {ExportHandler} from '../Dashboard/Pages/Reports/Components/ExportHandler'

interface IDownloadsProps {
    applicationData: IAuthenticatedAppData;
}

interface IDownloadsState {
}

export class Downloads extends React.Component<IDownloadsProps, IDownloadsState>{
    exportHandlerRef: React.RefObject<ExportHandler>;
    constructor(props: IDownloadsProps) {
        super(props);
        this.exportHandlerRef = React.createRef();
        
    }

    render() {
        return (
            <Row>
                <Col>
                    <Row>
                        <Col>
                            <h1>{this.props.applicationData.resources.DocumentDownloadHeader}</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <Table bordered style={{border: "2px solid black"}}>
                            <tr>
                                <th style={{border: "1px solid black"}}>{this.props.applicationData.resources.DocumentDownloadDescription}</th>
                                <th style={{border: "1px solid black"}}>{this.props.applicationData.resources.DocumentDownloadDownload}</th>
                            </tr>
                            <tr>
                                <td style={{border: "1px solid black"}}>{this.props.applicationData.resources.VeriliteUserGuideDescription}</td>
                                <td style={{border: "1px solid black"}}>                            
                                    <Button outline color="primary" onClick={() => {
                                        try {
                                            this.exportHandlerRef.current?.handleExport(postJson(Endpoints.GetDocument,
                                                JSON.stringify({FileName:"VeriLiteUserGuide"}),
                                                undefined , 
                                                true,  
                                                new UserRequestHeaders({ ...Auth.getUser(), Location: this.props.applicationData.selectedLocation })
                                                ));                    
                                        } catch (e) {
                                            console.error(e);
                                        }
                                    }}>{this.props.applicationData.resources.VeriliteUserGuide}</Button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{border: "1px solid black"}}>{this.props.applicationData.resources.VeriliteReleaseNotesDescription}</td>
                                <td style={{border: "1px solid black"}}>                            
                                    <Button outline color="primary" onClick={() => {
                                        try {
                                            this.exportHandlerRef.current?.handleExport(postJson(Endpoints.GetDocument,
                                                JSON.stringify({FileName:"VeriLiteReleaseNotes"}),
                                                undefined , 
                                                true,  
                                                new UserRequestHeaders({ ...Auth.getUser(), Location: this.props.applicationData.selectedLocation })
                                                ));                    
                                        } catch (e) {
                                            console.error(e);
                                        }
                                    }}>{this.props.applicationData.resources.VeriliteReleaseNotes}</Button>
                                </td>
                            </tr>
                        </Table>
                        <ExportHandler ref={this.exportHandlerRef} resources={this.props.applicationData.resources}/>
                        </Col>
                    </Row>
                </Col>
            </Row>

        );
    }
}