import * as React from 'react';
import * as SignalR from "@microsoft/signalr";
import { Auth, AuthenticationState, IAuthenticationService, AuthenticationService } from '../Services/AuthenticationService';
import { SignalRConnection } from '../Services/ConnectionService';
import { DataLoader, Layout, SafeRedirect, CustomSpinner, Center, FullView } from '../Components';
import * as SiteLogo from 'Assets/Images/site_logo.png';
import * as CompanyLogo from 'Assets/Images/company_logo.png';
import { EventModal } from '../Components/EventModal';
import { GlobalProvider, AuthProvider } from '../Context';
import { Fade, Spinner, Row, Col } from 'reactstrap';
import { Route } from 'react-router';
import { Switch } from 'react-router-dom';
import { Dashboard, CalibrateScanner, Settings, Echo, LicenseError, Downloads, } from '../Pages';
import { EventHandlerService, EventHandlerProvider } from '../Services/EventHandlerService';
import Endpoints from '../Utils/Endpoints';
import './Styles/AuthenticatedApp.css';
import { getJson, UserRequestHeaders } from '../Services';
import { PasswordResetLanding, TwoFactorAuthentication } from './Components';
import History from '../Utils/History';
import { VeriLiteInformationEvent, VeriLiteEventAction } from '../Types/Exports';

export interface IAuthenticatedAppProps {
    location: any;
}

export interface IAuthenticatedAppState {
    isConnected: boolean;
    finishedLoading: boolean;    
    applicationData: IAuthenticatedAppData;
    eventHandler: EventHandlerService;
}

export interface IAuthenticatedAppData {
    [key: string]: any;
    resources: any;
    supportedLanguages: any;
    selectedLanguage: any;
    userInfo: any;
    availableActions: any;
    calibrationData: any;
    locations: any;
    selectedLocation: any;
    apiProvider: any;
    multipleQueueCount: any;
    deferredQueueCount: any;
    connectionState: SignalR.HubConnectionState;
    nbsIsConnected: boolean;
    version: string;
    mvpLink: string;
    showHelpLink: any;
    updateCallback: (key: string, value: any) => void;
}

export class AuthenticatedApp extends React.Component<IAuthenticatedAppProps, IAuthenticatedAppState> {
        
    constructor(props: IAuthenticatedAppProps) {
        super(props);
        //console.log("AuthenticatedApp constructor")
        EventHandlerProvider.Set(
            (eventHandler: EventHandlerService) => {
                this.setState({
                    eventHandler: eventHandler
                });
            }
        );

        this.state = {             
            isConnected: SignalRConnection.isConnected,
            finishedLoading: false,
            applicationData: {
                selectedLocation: sessionStorage.getItem(AuthenticationService.selectedLocationKey),
                updateCallback: (key, value) => this.updateAppData(key, value)
            } as IAuthenticatedAppData,
            eventHandler: EventHandlerProvider.GetInstance()
        };
        //console.log("AuthenticatedApp constructor initial state")
        //console.log(this.state)

        getJson(Endpoints.GetResources, undefined, false).then(json => {
            var res = JSON.parse(json);
            //console.log("update resources")
            this.setState({ applicationData: { ...this.state.applicationData, resources: res } });
            Auth.init((location: string) => { /*console.log(`auth init update location: ${location}`);*/ this.setState({ applicationData: {...this.state.applicationData, selectedLocation:location}})});

            this.authStateUpdated(false);
        })
    }

    componentDidMount() {
        //console.log("AuthenticatedApp componentDidMount")
        var sessionLocation = sessionStorage.getItem(AuthenticationService.selectedLocationKey);        
        if (this.state.applicationData.selectedLocation != sessionLocation) {
            //console.log(`AuthenticatedApp componentDidMount update location ${sessionLocation}`)
            this.setState({ applicationData: { ...this.state.applicationData, selectedLocation: sessionLocation } });
        }
    }

    componentDidUpdate() {
        //console.log("AuthenticatedApp componentDidUpdate")
        if (this.state.finishedLoading) {
            //console.log("AuthenticatedApp componentDidUpdate not waiting for loading")
            var sessionLocation = sessionStorage.getItem(AuthenticationService.selectedLocationKey);
            if (sessionLocation == undefined || sessionLocation == "undefined" || sessionLocation == null) {
                //console.log("AuthenticatedApp componentDidUpdate session location is scuffed")
                if (this.state.applicationData.locations.length == 0) {
                    //console.log("AuthenticatedApp componentDidUpdate org has no locations")
                    if (this.props.location.pathname != '/Settings') {
                        //console.log("AuthenticatedApp componentDidUpdate not on settings page")
                        var eventHandler = EventHandlerProvider.GetInstance();
                        //console.log("AuthenticatedApp componentDidUpdate get event handler")
                        if (!eventHandler.HasEvent()) {
                            //console.log("AuthenticatedApp componentDidUpdate first time setup event")
                            EventHandlerProvider.GetInstance().AddEvent(new VeriLiteInformationEvent(this.state.applicationData.resources.FirstTimeSetupMessage, this.state.applicationData.resources.FirstTimeSetupTitle, [new VeriLiteEventAction( this.state.applicationData.resources.Ok, "primary",  (currentEvent, closeModal) => { History.push('/Settings'); closeModal(); } )]));
                        }
                    }
                }
                else {
                    //console.log("AuthenticatedApp componentDidUpdate has locations, set selected to default")
                    //console.log(Auth.getUser().Location)
                    this.updateAppData(AuthenticationService.selectedLocationKey, Auth.getUser().Location);
                }
            }
            else {
                //console.log("AuthenticatedApp componentDidUpdate session location is good")
                if (this.state.applicationData.selectedLocation == undefined || this.state.applicationData.selectedLocation == "undefined" || this.state.applicationData.selectedLocation == null) {
                    //console.log("AuthenticatedApp componentDidUpdate state location is scuffed, replace with session location")
                    this.setState((prevState: IAuthenticatedAppState) => { return { applicationData: { ...prevState.applicationData, selectedLocation: sessionLocation } }; });
                }
            }
        }
    }

    updateAppData = (key: string, value: any) => {
        //console.log("AuthenticatedApp updateAppData")
        //console.log(`AuthenticatedApp updateAppData update state: ${key}`)
        //console.log(value)
        this.setState((prevState) => ({
            applicationData: { ...prevState.applicationData, [key]: value }
        }));
        if (key == AuthenticationService.selectedLocationKey) {
            sessionStorage.setItem(AuthenticationService.selectedLocationKey, value);
            SignalRConnection.syncConnection(Auth.getUser().Organisation, value);
            this.updateQueueCount(true);
            this.updateQueueCount(false);
        }
    };

    updateQueueCount = (isStoreAndForward: boolean) => {
        //console.log("AuthenticatedApp updateQueueCount")
        if (!isStoreAndForward) {
            getJson(Endpoints.GetMultipleQueueCount, undefined, true, new UserRequestHeaders({ ...Auth.getUser(), Location: this.state.applicationData.selectedLocation || sessionStorage.getItem(AuthenticationService.selectedLocationKey)}))
                .then((response) => {
                    var value = JSON.parse(response);
                    this.updateAppData("multipleQueueCount", value);
                    //this.setState({ applicationData: { ...this.state.applicationData, multipleQueueCount: value}})
                })
                .catch(reason =>
                    console.error("Error loading multipleQueueCount. With reason: " + reason)
                );
        }
        else {
            getJson(Endpoints.GetDeferredQueueCount, undefined, true, new UserRequestHeaders({ ...Auth.getUser(), Location: this.state.applicationData.selectedLocation || sessionStorage.getItem(AuthenticationService.selectedLocationKey) }))
                .then((response) => {
                    var value = JSON.parse(response);
                    this.updateAppData("deferredQueueCount", value);
                    //this.setState({ applicationData: { ...this.state.applicationData, multipleQueueCount: value}})
                })
                .catch(reason =>
                    console.error("Error loading deferredQueueCount. With reason: " + reason)
                );
        }
    }


    loading = () => {
        //console.log("AuthenticatedApp loading")
        return (
            <FullView horizontal vertical>
                <Center horizontal vertical>
                    <CustomSpinner />
                </Center>
                <EventModal backdrop={"static"} />
            </FullView>
        );
    }

    authStateUpdated = (forceUpdate: boolean = true) => {
        //console.log("AuthenticatedApp authStateUpdated")
        if (Auth.State == AuthenticationState.Authenticated) {
            //console.log("AuthenticatedApp authStateUpdated SignalRConnection init")
            SignalRConnection.init( 
                Auth,
                (isConnected) =>
                    this.setState(
                        {
                            isConnected: isConnected
                        },
                        () => {
                            if (isConnected) {                                
                                SignalRConnection.syncConnection(
                                    Auth.getUser().Organisation,
                                    this.state.applicationData.selectedLocation || sessionStorage.getItem(AuthenticationService.selectedLocationKey)
                                );
                            }
                        }
                    ),
                this.updateQueueCount,
                (newConnectionState: SignalR.HubConnectionState) => {
                    this.setState({ applicationData: { ...this.state.applicationData, connectionState: newConnectionState } });
                },
                (isOnline: boolean) => {
                    this.setState({ applicationData: { ...this.state.applicationData, nbsIsConnected: isOnline } });
                },
            this.state.applicationData.resources);
        }
        if (forceUpdate) {
            this.forceUpdate();
        }
    }

    render() {
        //console.log("AuthenticatedApp render")
        switch (Auth.State) {
            case AuthenticationState.Unauthenticated:
                //console.log("AuthenticatedApp render Unauthenticated")
                return this.loading();
            case AuthenticationState.Loading:
                //console.log("AuthenticatedApp render Loading")
                return this.loading();
            case AuthenticationState.Error:
                //console.log("AuthenticatedApp render Error")
                return <EventModal backdrop={"static"} />;
            case AuthenticationState.Authenticated:
                //console.log("AuthenticatedApp render Authenticated")
                return (
                    <div>
                        <EventModal backdrop={true} />
                        {!this.state.finishedLoading ?
                            <DataLoader dataToLoad={[
                                { key: "supportedLanguages", endpoint: Endpoints.GetSupportedLanguages },
                                { key: "selectedLanguage", endpoint: Endpoints.GetSelectedLanguage },
                                { key: "userInfo", endpoint: Endpoints.GetUserInfo },
                                { key: "availableActions", endpoint: Endpoints.GetAvailableActions },
                                { key: "calibrationData", endpoint: Endpoints.GetCalibrationData },
                                { key: "locations", endpoint: Endpoints.GetLocations },
                                { key: "apiProvider", endpoint: Endpoints.GetApiProvider },
                                { key: "multipleQueueCount", endpoint: Endpoints.GetMultipleQueueCount },
                                { key: "deferredQueueCount", endpoint: Endpoints.GetDeferredQueueCount },
                                { key: "version", endpoint: Endpoints.GetVersion },
                                { key: "mvpLink", endpoint: Endpoints.GetMvpLink },
                                { key: "showHelpLink", endpoint: Endpoints.GetShowHelpLink}
                            ]}
                                onComplete={() => { /*console.log("AuthenticatedApp render loading complete");*/ this.setState({ finishedLoading: true }); }}
                                onUpdate={(key: string, value: any) => {
                                    //console.log(`AuthenticatedApp render loading update state ${key}`)
                                    //console.log(value)
                                    this.setState((prevState) => ({
                                        applicationData: { ...prevState.applicationData, [key]: value }
                                    }));
                                }}
                                siteLogo={SiteLogo}
                                isConnected={this.state.isConnected}
                                location={this.state.applicationData.selectedLocation}
                                resources={this.state.applicationData.resources}
                            />
                            :
                            <GlobalProvider value={this.state.applicationData}>
                                <AuthProvider value={Auth}>
                                    <Fade timeout={300}>
                                        <Layout siteLogo={SiteLogo} companyLogo={CompanyLogo}>
                                            <Switch>
                                                <Route exact path='/Dashboard/:path?/:path2?' render={(props) =>
                                                    <Dashboard {...props}
                                                        resources={this.state.applicationData.resources}
                                                        siteLogo={SiteLogo}
                                                        companyLogo={CompanyLogo}
                                                        supportedLanguages={this.state.applicationData.supportedLanguages}
                                                    />}
                                                />
                                                <Route exact path='/CalibrateScanner' render={(props) => <CalibrateScanner {...props} applicationData={this.state.applicationData} />} />
                                                <Route exact path='/Settings/:path?' render={(props) => <Settings {...props} resources={this.state.applicationData.resources} />} />
                                                <Route exact path='/EchoPage' render={(props) => <Echo />} />
                                                <Route exact path='/Downloads' render={(props) => <Downloads  applicationData={this.state.applicationData} />} />
                                                <Route render={() => <SafeRedirect to="/Dashboard/Safe" resources={this.state.applicationData.resources}/>} />
                                            </Switch>
                                        </Layout>
                                    </Fade>
                                </AuthProvider>
                            </GlobalProvider>}
                    </div>
                );
            case AuthenticationState.PasswordReset:
                //console.log("AuthenticatedApp render PasswordReset")
                return <PasswordResetLanding resources={this.state.applicationData.resources}/>
            case AuthenticationState.TwoFactor:
                //console.log("AuthenticatedApp render TwoFactor")
                return <TwoFactorAuthentication forceAppUpdate={this.authStateUpdated} siteLogo={SiteLogo} companyLogo={CompanyLogo} resources={this.state.applicationData.resources} />
            case AuthenticationState.LicenseError:
                //console.log("AuthenticatedApp render LicenseError")
                return <LicenseError resources={this.state.applicationData.resources}/>
        }
    }
}