import * as React from 'react';
import { Row, Col, ButtonGroup, Button, InputGroupAddon, InputGroupText } from 'reactstrap';
import { RequestLookupType, IRequestLookupFilters } from '../Types';
import { UprcFilter, TransactionIdFilter, PackIdentifierFilter } from '../Components';
import { IResources } from '../../../../../../../Types';
import DatePicker from 'reactstrap-date-picker';

interface IRequestLookupFiltersProps {
    selectedType: RequestLookupType;
    changeRequestLookupType: (type: RequestLookupType) => void;
    fetchResults: (filters: IRequestLookupFilters) => void;
    resources: IResources;
    filterFrom: string;
    filterTo: string;
    onFilterDateChange: (state: string, newDate: string) => void
}

interface IRequestLookupFiltersState {

}

export class RequestLookupFilters extends React.Component<IRequestLookupFiltersProps, IRequestLookupFiltersState>{
    packIdentiferButtonRef: React.RefObject<HTMLButtonElement>;
    uprcButtonRef: React.RefObject<HTMLButtonElement>;
    transactionIdButtonRef: React.RefObject<HTMLButtonElement>;
    maxDaySearch: string 

    constructor(props: IRequestLookupFiltersProps) {
        super(props);

        this.packIdentiferButtonRef = React.createRef<HTMLButtonElement>();
        this.uprcButtonRef = React.createRef<HTMLButtonElement>();
        this.transactionIdButtonRef = React.createRef<HTMLButtonElement>();
        this.maxDaySearch = process.env.REACT_APP_MAXDAYSSEARCH ?? 14;
    }

    changeRequestLookupType = (type: RequestLookupType) => {
        this.props.changeRequestLookupType(type);

        this.packIdentiferButtonRef.current?.blur();
        this.uprcButtonRef.current?.blur();
        this.transactionIdButtonRef.current?.blur();
    };

    fetchResults = (filters: IRequestLookupFilters) => {
        this.props.fetchResults(filters);
    }

    
    getDate = (offset: number = 0, date: Date = new Date()): string=> {
        date.setDate(new Date().getDate() + offset);
        return date.toISOString();
    };

    render() {
        const filters = () => {
            switch (this.props.selectedType) {
                case RequestLookupType.PackIdentifer:
                    return <PackIdentifierFilter fetchResults={this.fetchResults} resources={this.props.resources}/>
                case RequestLookupType.TransactionId:
                    return <TransactionIdFilter fetchResults={this.fetchResults} resources={this.props.resources}/>
                case RequestLookupType.UPRC:
                    return <UprcFilter fetchResults={this.fetchResults} resources={this.props.resources}/>
                case RequestLookupType.None:
                default:
                    return <div>{this.props.resources.RequestLookup_PleaseChooseAnOption}</div>;
            }
        };
        return (
            <div className="mb-4">
                <Row>
                    <Col />
                    <Col className="mb-2" xs="auto">
                        <ButtonGroup size="lg">
                            <Button outline color="primary" innerRef={this.packIdentiferButtonRef} onClick={() => this.changeRequestLookupType(1)} active={this.props.selectedType === 1}>{this.props.resources.PackIdentifer}</Button>
                            <Button outline color="primary" innerRef={this.uprcButtonRef} onClick={() => this.changeRequestLookupType(2)} active={this.props.selectedType === 2}>{this.props.resources.UPRC}</Button>
                            <Button outline color="primary" innerRef={this.transactionIdButtonRef} onClick={() => this.changeRequestLookupType(3)} active={this.props.selectedType === 3}>{this.props.resources.TransactionId}</Button>
                        </ButtonGroup>
                    </Col>
                    <Col />
                </Row>
                { this.props.selectedType != RequestLookupType.None &&(
                <Row>
                <Col className="mb-2" xs="auto">
                        <DatePicker
                        value={this.props.filterFrom}
                            onChange={(value: string) => {this.props.onFilterDateChange("filterFrom", value)}}
                            showClearButton={false}
                            minDate={this.getDate(-this.maxDaySearch, new Date(Date.parse(this.props.filterTo)))}
                            maxDate={this.props.filterTo}
                        >
                            <InputGroupAddon addonType="append">
                                <InputGroupText>{this.props.resources.FilterFrom}</InputGroupText>
                            </InputGroupAddon>
                        </DatePicker>
                    </Col>
                    <Col className="mb-2" xs="auto">
                        <DatePicker
                            value={this.props.filterTo}
                            onChange={(value: string) => {this.props.onFilterDateChange("filterTo", value)}}
                            showClearButton={false}
                            maxDate={this.getDate()}>
                            <InputGroupAddon addonType="append">
                                <InputGroupText>{this.props.resources.FilterTo}</InputGroupText>
                            </InputGroupAddon>
                        </DatePicker>
                    </Col>
                </Row>
                )}
                {filters()}
            </div>
        );
    }
}