import * as React from 'react';
import { IAuthenticatedAppData } from '../../../../../../AuthenticatedApp/AuthenticatedApp';
import { RequestLookupType, IRequestLookupFilters, IRequestLookupRequest } from './Types';
import { RequestLookupFilters } from './Components';
import { ReportsTable } from '../../Components';
import { IReportsRequestData, ReportGenerator } from '../../Types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { Auth, UserRequestHeaders, postJson, ReportsRequestService } from '../../../../../../Services/';
import Endpoints from '../../../../../../Utils/Endpoints';
import { request } from 'http';

interface IRequestLookupProps {
    applicationData: IAuthenticatedAppData;
}

interface IRequestLookupState {
    selectedType: RequestLookupType;
    nonExpandable: any[];
    expanded: any[];
    request: IRequestLookupRequest;
    pending: boolean;
    tokens: any[];
    results: IReportsRequestData[];
}

export class RequestLookup extends React.Component<IRequestLookupProps, IRequestLookupState>{
    
    constructor(props: IRequestLookupProps) {
        super(props);
        this.state = {
            selectedType: RequestLookupType.None,
            nonExpandable: [],
            expanded: [],
            request: {
                token: null,
                page: 1,
                itemsCount: 10,
                filterFrom: this.getDate(-2),
                filterTo: this.getDate()
            } as IRequestLookupRequest,
            pending: false,
            tokens: [],
            results: [],
        };
    }

    filterDateChange = (state: string, newDate: string)=> {
        if (state === "filterFrom"){
            var dateFormat = new Date(Date.parse(newDate));
            dateFormat.setHours(0);
            this.setState((prevState) => ({request: {...prevState.request, filterFrom: dateFormat.toISOString()}}));
        }
        else{
            var dateFormat = new Date(Date.parse(newDate));
            dateFormat.setHours(23,59,59);
            
            if (new Date(Date.parse(newDate)) < new Date(Date.parse(this.state.request.filterFrom))){

                var dateFormatFrom = new Date(Date.parse(newDate));
                dateFormatFrom.setDate(dateFormat.getDate() - 1)
                dateFormatFrom.setHours(0);
                this.setState((prevState) => ({request: {...prevState.request,filterTo: dateFormat.toISOString(), filterFrom: dateFormatFrom.toISOString()}}));  
            }
            else{
                this.setState((prevState) => ({request: {...prevState.request, filterTo: dateFormat.toISOString()}}));
            }
        }

    }

    getDate = (offset: number = 0, date: Date = new Date()): string=> {
        date.setDate(new Date().getDate() + offset);
        return date.toISOString();
    };


    changeRequestLookupType = (newRequestType: RequestLookupType) => {
        if (this.state.selectedType != newRequestType) {
            this.setState({
                selectedType: newRequestType,
                results: [],
                tokens: [],
                pending: false
            });
        }
    }

    handledExpand = (id: any) => {
        var results = this.state.results.map((result) => {
            if (result.id == id) {
                result.serialNumber = <span style={{ cursor: "pointer" }} onClick={() => { this.handledExpand(id); }}><FontAwesomeIcon className="mr-2" icon={this.state.expanded.includes(id) ? faPlusCircle : faMinusCircle} />{result.action} ({result.packs?.length})</span>;
            }
            return result;
        });

        this.setState({
            expanded: this.state.expanded.includes(id) ? this.state.expanded.filter(x => x !== id) : [...this.state.expanded, id],
            results: results
        });
    };

    fetchResults = () => {
        var body: string = JSON.stringify(this.state.request);
        postJson(Endpoints.GetRequestLookup, body, undefined, true,  new UserRequestHeaders({ ...Auth.getUser(), Location: this.props.applicationData.selectedLocation })).then((results) => {
            var j = JSON.parse(results);
            var reportRows = ReportsRequestService.parseServerData(j.records);
            var data: IReportsRequestData[] = [];
            var nonExpandable: any[] = [];
            reportRows.map((row) => {
                nonExpandable.push(row.id);
                data.push(ReportGenerator.getReport(row, row.id, this.props.applicationData.resources, () => { this.handledExpand(row.id); }));
            });
            var tokens = this.state.tokens;
            if (j.token) {
                tokens.push(j.token);
            }
            this.setState((prevState) => ({
                results: data,
                pending: false,
                tokens: tokens,
                nonExpandable: nonExpandable
            }));
        })
            .catch((err) => console.error(err));
    };

    filterUpdate = (filters: IRequestLookupFilters) => {
        //this.requestTimer = Date.now();

        this.setState((prevState) => ({
            results: [],
            tokens: [],
            request: { ...prevState.request, productCode: filters.productCode, serialNumber: filters.serialNumber, transactionId: filters.transactionId, uprc: filters.uprc, token: null, page: 1 },
            pending: true
        }),
            this.fetchResults);
    };

    tableUpdate = (page: number, itemCount: number) => {
        this.setState({ pending: true, results: [] });
        var tokens = this.state.tokens;
        if (this.state.request.page > page) {
            tokens.pop();
            if (tokens.length == page) {
                tokens.pop();
            }
        }

        var token = tokens.length > 0 ? tokens[tokens.length - 1] : null;

        this.setState((prevState) => ({
            request: { ...prevState.request, page: page, itemCount: itemCount, token: token },
            pending: true,
            tokens: tokens
        }),
            this.fetchResults);
    };

    getTablePage = () => {
        var totalSize = (this.state.request.page * 10) + (this.state.request.page <= this.state.tokens.length ? 1 : 0);
        return totalSize;
    };

    getExpandedRow = (): any => {
        return {
            renderer: (row: IReportsRequestData) => {
                var packs = row.packs?.map((pack) => {
                    return (
                        <tr key={pack.serialNumber}>
                            <td style={{ width: "30%" }}>
                                {pack.serialNumber}
                            </td>
                            <td style={{ width: "30%" }}>
                                {pack.submissionTime}
                            </td>
                            <td style={{ width: "15%" }}>
                                {pack.action}
                            </td>
                            <td style={{ width: "15%" }}>
                                {pack.RAG}
                            </td>
                            <td style={{ width: "10%" }}>
                                {pack.UPRC}
                            </td>
                        </tr >
                    );
                });

                return (
                    <table style={{ width: "100%" }}>
                        <tbody>
                            {packs}
                        </tbody>
                    </table >
                );
            },
            expanded: this.state.expanded,
            nonExpandable: this.state.nonExpandable
        };
    };
    
    render() {
        return (
            <div>
                <RequestLookupFilters selectedType={this.state.selectedType} changeRequestLookupType={this.changeRequestLookupType} fetchResults={this.filterUpdate} resources={this.props.applicationData.resources} filterFrom={this.state.request.filterFrom} onFilterDateChange={this.filterDateChange} filterTo={this.state.request.filterTo}/>
                {this.state.selectedType !== RequestLookupType.None && <ReportsTable tableChangeHandler={this.tableUpdate} page={this.state.request.page} totalSize={this.getTablePage()} data={this.state.results} pending={this.state.pending} expandRow={this.getExpandedRow()} resources={this.props.applicationData.resources} />}
            </div>
        );
    }
}