import * as React from 'react';
import { Row, Col, Input, InputGroup, InputGroupAddon, InputGroupText, CustomInput, Button } from 'reactstrap';
import { IAuthenticatedAppData } from '../../../../../../../AuthenticatedApp/AuthenticatedApp';
import { PackAction, PackState } from '../../../../../Types';
import DatePicker from 'reactstrap-date-picker';

interface IFilters {
    [key: string]: string | boolean | Date;
    FilterFrom: string;
    FilterTo: string;
    ActionFilter: string;
    AlertFilter: boolean;
}

interface IHistoryFilterState {
    filters: IFilters;
}

interface IHistoryFiltersProps {
    applicationData: IAuthenticatedAppData;
    fetchResults: (startDate: string, endDate: string, action: string, hasAlerts: boolean) => void;
}

export class HistoryFilters extends React.Component<IHistoryFiltersProps, IHistoryFilterState>{
    defaultFilter: IFilters;
    delaySearch: boolean;
    cancelSearch: boolean;
    searchInProgress: boolean;

    constructor(props: IHistoryFiltersProps) {
        super(props);
        this.defaultFilter = {
            ActionFilter: this.props.applicationData.resources.Filter_All,
            AlertFilter: false,
            FilterFrom: this.getDate(-2),
            FilterTo: this.getDate()
        };
        this.delaySearch = false;
        this.searchInProgress = false;
        this.cancelSearch = false;

        this.state = {
            filters: this.defaultFilter
        };
        props.applicationData.availableActions.map((action: PackAction) => {
        });

    }
    componentDidMount() {
        this.requestResults();
    }

    getDate = (offset: number = 0, date: Date = new Date()): string=> {
        date.setDate(new Date().getDate() + offset);
        return date.toISOString();
    };

    onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {

        var id = event.currentTarget.id;
        var value = event.currentTarget.id == "AlertFilter" ? event.currentTarget.checked : event.currentTarget.value;

        this.setState(
            (prevState) => {
                return {
                    filters: { ...prevState.filters, [id]: value }
                };
            },
            () => {
                if (!this.searchInProgress) {
                    this.requestResults();
                }
            });
    };

    dateOnChange = (value: string, key: string, hours: number, mins: number, secs: number) => {
        if (value == null) {
            value = this.defaultFilter[key].toString()
        }
        var dateFormat = new Date(Date.parse(value));
        dateFormat.setHours(hours, mins, secs);
        this.setState(
            (prevState) => {
                return {
                    filters: { ...prevState.filters, [key]: dateFormat.toISOString()}
                };
            },
            () => {
                if (!this.searchInProgress) {
                    this.requestResults();
                }
            });
    };

    reset = () => {

        this.setState({ filters: this.defaultFilter },
            () => {
                this.cancelSearch = true;
                this.props.fetchResults(this.state.filters.FilterFrom, this.state.filters.FilterTo, this.state.filters.ActionFilter, this.state.filters.AlertFilter);
            }
        );
    };

    requestResults = () => {
        if (this.searchInProgress) {
            return;
        }
        this.searchInProgress = true;
        setTimeout(() => {
            if (!this.delaySearch) {
                this.searchInProgress = false;
                if (!this.cancelSearch) {
                    this.props.fetchResults(this.state.filters.FilterFrom, this.state.filters.FilterTo, this.state.filters.ActionFilter == this.props.applicationData.resources.Filter_All ? 'All':this.state.filters.ActionFilter, this.state.filters.AlertFilter);
                }
                else {
                    this.cancelSearch = false;
                }
            } else {
                this.delaySearch = false;
                this.searchInProgress = false;
                this.requestResults();
            }
        }, 1500);
    }

    delay = () => {
        if (this.searchInProgress) {
            this.delaySearch = true;
        }
    }


    render() {
        var date = (new Date()).toISOString().substring(0, 10);
        return (
            <Row>
                <Col>
                    <Row>
                        <Col>
                            <DatePicker
                                value={this.state.filters.FilterFrom}
                                onClick={this.delay}
                                onChange={(value:string) => { this.dateOnChange(value, "FilterFrom", 0, 0, 0); }}
                                showClearButton={false}
                                minDate={this.getDate(-50, new Date(Date.parse(this.state.filters.FilterFrom)))}
                                maxDate={this.state.filters.FilterTo}
                            >
                                <InputGroupAddon addonType="append">
                                    <InputGroupText>{this.props.applicationData.resources.FilterFrom}</InputGroupText>
                                </InputGroupAddon>
                            </DatePicker>
                        </Col>

                        <Col>
                            <DatePicker
                                value={this.state.filters.FilterTo}
                                onClick={this.delay}
                                onChange={(value:string) => { this.dateOnChange(value, "FilterTo",23, 59, 59); }}
                                showClearButton={false}
                                minDate={this.state.filters.FilterFrom}
                                maxDate={this.getDate()}>
                                <InputGroupAddon addonType="append">
                                    <InputGroupText>{this.props.applicationData.resources.FilterTo}</InputGroupText>
                                </InputGroupAddon>
                            </DatePicker>
                        </Col>
                    </Row>
                    <Row className="my-2">
                        <Col>
                            <InputGroup>                                
                                <Input
                                    type="select"
                                    id="ActionFilter"
                                    onChange={this.onChangeHandler}
                                    onClick={this.delay}
                                    value={this.state.filters.ActionFilter}
                                >
                                    <option key={this.props.applicationData.resources.Filter_All} value="All">{this.props.applicationData.resources.Filter_All}</option>
                                    {this.props.applicationData.availableActions.map((action: PackAction) => {
                                        var packAction = new PackAction(action.value, action.displayName);
                                        return <option
                                            value={PackState.parse(packAction.value).value}
                                            key={packAction.value}
                                        >{packAction.displayName}
                                        </option>;
                                    }                                    
                                    )}
                                    <option key={PackAction.Reactivate.value} value={PackState.Active.value}>{PackAction.Reactivate.displayName}</option>
                                </Input>
                                <InputGroupAddon addonType="append">
                                    <InputGroupText>{this.props.applicationData.resources.ShowBy}</InputGroupText>
                                </InputGroupAddon>
                            </InputGroup>
                        </Col>

                        <Col>
                            <Row className="fv-c">                                
                                <Col md="auto">
                                    {this.props.applicationData.resources.ShowAlertsOnly}
                                </Col>
                                <Col>
                                    <CustomInput
                                        type="checkbox"
                                        id="AlertFilter"
                                        onClick={this.delay}
                                        onChange={this.onChangeHandler}
                                        checked={this.state.filters.AlertFilter}
                                    />
                                </Col>                                
                                <Col>
                                    <Button color="primary" onClick={this.reset}>{this.props.applicationData.resources.CancelScan}</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}